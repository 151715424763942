import { Drawer, makeStyles } from "@material-ui/core"
import React from "react"
import EditUser from "./EditUser"
import NewUser from "./NewUser"

const useStyle = makeStyles(theme => ({
  root: {
    width: 250,
    height: "100%",
  },
}))

function UserDrawerWrapper({ handleClose, open, content, isEdit }) {
  const classes = useStyle()

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      containerStyle={{ height: "calc(100% + 64px)" }}
      PaperProps={{
        style: { width: 500 },
      }}
      className={classes.root}
      open={open}
      onClose={handleClose}
    >
      <div style={{ height: 1000 }}>
        {content === "NewUser" && (
          <NewUser close={handleClose} isEdit={isEdit} />
        )}
        {content === "EditUser" && (
          <EditUser close={handleClose} isEdit={isEdit} />
        )}
      </div>
    </Drawer>
  )
}

export default UserDrawerWrapper
