import { IconButton, makeStyles, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CloseIcon from "@material-ui/icons/Close"
import DrawerSelect from "./utils/DrawerSelect"
import DrawerTextField from "./utils/DrawerTextField"
import DrawerButton from "./utils/DrawerButton"
import delete_icon from "../../../images/delete_black.svg"
import { actions } from "../../../redux/slices/manageUserSlice"
import { useSelector, useDispatch } from "react-redux"
import { DATA_PERMISSIONS, DATA_STATUS } from "../../../utils/constants"
import Spinner from "../../components/Loader/Spinner"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  titleRoot: {
    width: "97%",
    display: "flex",
    marginTop: "40px",
    marginBottom: "30px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "15px",
  },
  rootForm: {
    width: "100%",
  },
  rootMainForm: {
    paddingLeft: "15px",
    paddingRight: "15px ",
  },
  dateTime: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginRight: "10px",
  },
  rootButton: {
    display: "flex",
    width: "97%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    bottom: "0",
    position: "absolute",
    marginBottom: "20px",
    overflowY: "hidden",
    marginTop: "-25px",
  },
  typoTitle: {
    fontFamily: "DM Sans",
    fontSize: "30px",
    fontWeight: 400,
  },
  editRootButton: {
    display: "flex",
    width: "97%",
    flexDirection: "row",
    justifyContent: "space-arround",
    alignItems: "center",
    bottom: "0",
    position: "absolute",
    marginBottom: "20px",
    overflowY: "hidden",
  },
  errorText: {
    color: "#FD6E84",
    fontSize: "12px",
    margin: "0px 0px 10px 10px",
  },
}))

function NewUser({ close, isEdit }) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const userFormState = useSelector(state => state.manageUserReducer.userInfo)
  const userState = useSelector(state => state.userSlice?.user)
  const emailError = useSelector(state => state.manageUserReducer.error)
  const drawerClose = useSelector(state => state.manageUserReducer.close)
  const loading = useSelector(state => state.manageUserReducer.loading)

  const [formErrors, setFormsErrors] = useState({})

  useEffect(() => {
    if (drawerClose) {
      close()
      dispatch(actions.manageUserDrawerClose(false))
      dispatch(actions.manageUserErrorMessage(""))
    }
  }, [close])

  const handleChange = (key, value) => {
    let temp = { ...formErrors }

    delete temp[key]

    setFormsErrors({ ...temp })
    dispatch(
      actions.manageUserFieldsInfo({ key1: "userInfo", key2: key, value })
    )
  }

  const addUser = async () => {
    dispatch(
      actions.sendInvites({
        body: { ...userFormState },
        params: { clubId: userState?.userInfo?.clubId },
      })
    )
  }

  const handleDelete = async () => {
    dispatch(
      actions.deleteInvites({
        params: {
          clubId: userState?.userInfo?.clubId,
          inviteId: userFormState?.id,
          userId: userState?.uid,
        },
      })
    )
  }

  const handleUserEdit = () => {
    dispatch(
      actions.editInvites({
        body: { ...userFormState },
        params: {
          clubId: userState?.userInfo?.clubId,
          inviteId: userFormState?.id,
        },
      })
    )
    close()
  }

  const validateFields = index => {
    const { clubId, ...temp } = userFormState
    let err = {}
    Object.keys(temp).map(key => {
      if (temp[key] === "") {
        err = { ...err, [key]: `Required*` }
      }

      if (key === "email" && temp[key] !== "") {
        let matchPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          temp[key]
        )

        if (!matchPattern) {
          err = { ...err, [key]: `not a valid email*` }
        }
      }
    })
    return err
  }

  const validations = () => {
    let errors = {}

    let err = validateFields()

    let { ...errorsList } = err

    if (Object.keys(errorsList).length > 0) {
      errors = { ...errors, ...errorsList }
    }

    if (Object.keys(errors).length === 0) {
      if (isEdit) {
        handleUserEdit()
      } else {
        addUser()
      }
    }
    setFormsErrors({ ...formErrors, ...errors })
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleRoot}>
        <Typography className={classes.typoTitle} style={{ margin: "13px" }}>
          {isEdit ? "Edit user" : "New user"}
        </Typography>
        <IconButton onClick={close}>
          <CloseIcon style={{ margin: "13px" }} />
        </IconButton>
      </div>
      <div className={classes.rootForm}>
        <div className={classes.rootMainForm}>
          <div style={{ marginRight: "15px" }}>
            <DrawerTextField
              errors={formErrors?.name}
              onChange={e => handleChange("name", e.target.value)}
              value={userFormState?.name}
              placeHolder="Name"
              label="Name"
            />
          </div>
          <div style={{ marginRight: "15px" }}>
            <DrawerTextField
              disable={isEdit}
              errors={formErrors?.email || emailError}
              onChange={e => handleChange("email", e.target.value)}
              value={userFormState?.email}
              placeHolder="Email"
              label="Email"
            />
            {emailError !== "" && (
              <Typography className={classes.errorText}>
                {emailError}
              </Typography>
            )}
          </div>
          <div className={classes.dateTime}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "0px",
              }}
            >
              <DrawerSelect
                data={DATA_PERMISSIONS}
                errors={formErrors?.permission}
                onChange={value => handleChange("permission", value.value)}
                value={DATA_PERMISSIONS.find(
                  item => item.value === userFormState.permission
                )}
                align="center"
                placeHolder="Permission"
                label="Permission"
              />
            </div>
            <div
              style={{
                display: "flex",
                direction: "column",
                width: "100%",
                marginLeft: "10px",
                marginRight: "5px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <DrawerSelect
                data={DATA_STATUS}
                errors={formErrors?.status}
                onChange={value => handleChange("status", value.label)}
                value={DATA_STATUS.find(
                  item => item.label === userFormState.status
                )}
                placeHolder="Active"
                label="Active"
              />
            </div>
          </div>

          {isEdit ? (
            <div className={classes.editRootButton}>
              <div style={{ width: "80%", padding: "20px" }}>
                <DrawerButton text="Save" handleOnClick={() => validations()} />
              </div>
              {loading ? (
                <Spinner />
              ) : (
                <div onClick={handleDelete} style={{ width: "20%" }}>
                  <img
                    src={delete_icon}
                    style={{ width: "60px", cursor: "pointer" }}
                    alt=""
                  />
                </div>
              )}
            </div>
          ) : (
            <div className={classes.rootButton}>
              <div
                style={{
                  width: "100%",
                  padding: "20px",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "100px",
                }}
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <DrawerButton
                    text="Add User"
                    handleOnClick={() => {
                      validations()
                    }}
                  />
                )}
              </div>
              <div style={{ width: "60%" }}>
                <Typography
                  noWrap={false}
                  style={{
                    fontSize: "12px",
                    fontFamily: "DM Sans",
                    textJustify: "none",
                    textAlign: "center",
                    color: "#B7B7BF",
                  }}
                >
                  Note that you can not change email or name once you have
                  created a new user account
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NewUser
